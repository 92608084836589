import React from "react";
import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";
import music from '../images/music.png';
import muz from '../video/cumetri99.mp3'
import CardLocal from "../cardLocal";
import FormSection from "../FormSection";
import BlogSectionNoi from "../blogSectionNoi";
import data from "../Data";
import CardInvitatie from "./CardInvitatie";
import Suspendare from "./Suspendare";



const Home = (props) => {


    return (



        <ParallaxProvider>

       {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 550 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".8" >
                       <div className="mask" > </div>
                        <div className="row text-center align-items-center" style={{ position: 'relative'}}>
                            <div className="col-12 mb-5" style={{ maxWidth: '600px', padding: '30px', zIndex: '3'  }}>
                                <h1 className="animate__animated animate__bounceInLeft" style={{ fontSize: '60px', textShadow: `3px 3px 5px white, 0 0 4em white, 0 0 3em pink`,}}>{item.copilul}</h1>
                                <h4 className="animate__animated animate__bounceInRight animate__delay-2s animate__slower" style={{ fontSize: '36px' }}>{item.savedata}<br />{item.data}</h4>
                                <Timer />
                            </div>
                        </div>
                    </Parallaximg>
                )
            })} 

   {/** <Suspendare/>*/} 


   <BlogSectionNoi />
  
<div className="text-center mt-4 pt-4">
                <span className="text-center">
                    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music} />
                    <audio src={muz} controls autoPlay loop ></audio>
                </span>
            </div>

            {data.blogDataDrum.map((item) => {
                return (
                   
                        <div className="row text-center " >
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '30px', margin: 'auto' }}>
                                <h1 style={{ fontSize: '40px', }}>{item.title}</h1>
                                <h4 style={{}}>{item.message}</h4>
                            </div>
                        </div>
                  
                )
            })} 


            

       {/** Nu intra in invitatie
     {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="400px" opacity=".8">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 mb-3" style={{ maxWidth: '600px', padding: '30px'  }}>
                                <h1 style={{ fontSize: '45px', textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em pink`  }}>{item.title}</h1>
                                <h4 >{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })} 
*/}

          <CardInvitatie/>


         <CardLocal />
    

            <FormSection /> 
            {data.introData.map((item) => {
                return (
                    <div>
                        <h5 className="text-center mt-5" >
                        Vă așteptăm cu nerăbdare, <br/> de la mic și până la mare!
                        </h5>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                        {item.familia}
                        </h3>
                    </div>

                )
            })}






        </ParallaxProvider>



    )
}

export default Home;